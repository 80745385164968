import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/app/router/routes'
import { sellerRoutes } from '@/app/router/seller-routes'
import { buyerRoutes } from '@/app/router/buyer-routes'
import { container } from 'tsyringe'
import SupabaseService from '@/infrastructures/services/SupabaseService'
import TracingAndLog from '@/infrastructures/misc/tracingAndLog'

const IS_SELLER = import.meta.env.VITE_APP_IS_SELLER === 'true'

const router = createRouter({
  history: createWebHistory(),
  routes: IS_SELLER ? sellerRoutes : [...buyerRoutes, ...routes],
})

router.onError((error) => {
  TracingAndLog.setExtraData('error_router', router.currentRoute.value)
  TracingAndLog.captureException(error)
})

router.beforeEach(async (to, from, next) => {
  const { useViewsStore } = await import('@/app/stores/views-store')
  const viewsStore = useViewsStore()
  viewsStore.toPage = to.fullPath
  viewsStore.fromPage = from.fullPath

  const toDashboard = () => {
    next({
      name: 'seller-dashboard',
    })
    return
  }
  const toLogin = () => {
    next({
      name: 'login',
    })
    return
  }

  if (to.path.includes('/seller')) {
    const path = to.path.replace('/seller', '')

    if (IS_SELLER) {
      if (!path || path === '/') {
        toDashboard()
      } else {
        next(path)
        return
      }
    } else {
      const sellerSite = import.meta.env.VITE_APP_SELLER_SITE
      if (!path || path === '/') {
        window.location.replace(sellerSite)
      } else {
        window.location.replace(sellerSite + `${path}`)
        return
      }
    }
  }

  if (IS_SELLER) {
    if (to.name === 'landing') {
      toLogin()
    }
    const { useAuthStore } = await import('@/app/stores/auth-store')
    const authStore = useAuthStore()

    const { data } = await container.resolve(SupabaseService).supabase.auth.getSession()

    const hasSession: boolean = Date.parse(authStore.session.expiresAt.toString()) > Date.now()
    const isAuth: boolean =
      authStore.isLoggedIn() && !!data && !!(data as any)?.session && hasSession

    if ((to.name === 'login' || to.name === 'register' || to.name === 'referral-register' || to.name === 'referral-login') && isAuth) {
      toDashboard()
    }

    if (to.name === 'NotFound' || to.name === 'login' || to.name === 'register' || to.name === 'referral-register' || to.name === 'referral-login') next() 

    if (!isAuth) {
      authStore.resetAccount()
      authStore.resetSeller()
      authStore.resetSession()
      toLogin()
    }
  }

  next()
})

export default router
